import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getCourseConfig, SUBJECT_CONFIG } from 'my-core/course-utils';
import { landingPageCTAClicked } from 'my-core/gtm-events';

import SubjectIcon from 'my-elements/SubjectIcon';

import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(
  ({ breakpoints, palette, shape: { borderRadiusSm }, spacing, typography }) => ({
    root: {
      display: 'flex',
      padding: spacing(3, 0),
      overflowX: 'auto',
    },
    root_wrapped: {
      overflowX: 'visible',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    subjectFilter: {
      flex: '0 0 110px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '$root:not($root_wrapped) &': {
        '&:first-of-type': { marginLeft: 'auto' },
        '&:last-of-type': { marginRight: 'auto' },
      },
    },
    subjectFilter_large: {
      backgroundColor: palette.background.default,
      margin: spacing(2),
      padding: spacing(3, 4),
      border: [[2, 'solid', 'transparent']],
      borderRadius: borderRadiusSm,
      '& $subjectIcon': { width: 70, height: 70, marginBottom: spacing(2) },
    },
    subjectFilter_active: {
      '&$subjectFilter_large': { borderColor: palette.primary.main },
      '&:not($subjectFilter_large) $subjectFilterLabel': {
        fontWeight: typography.fontWeightExtraBold,
        paddingBottom: 4,
        borderBottom: [[3, 'solid', palette.text.primary]],
      },
    },
    subjectFilterIconBtn: {
      padding: 0,
      marginBottom: spacing(1),

      '& $subjectIcon': { width: 50, height: 50 },
    },
    subjectIcon: {},
    subjectFilterLabel: {},
    [breakpoints.only('xs')]: {
      root: { padding: spacing(1, 0) },
      subjectFilter: { flex: '0 0 70px' },
      subjectFilter_large: {
        padding: spacing(2, 3),
        border: [[2, 'solid', 'transparent']],
        '& $subjectIcon': { width: 50, height: 50, marginBottom: spacing(2) },
      },
      subjectFilterIconBtn: {
        '& $subjectIcon': { width: 30, height: 30 },
      },
      subjectFilterLabel: {
        fontSize: typography.caption.fontSize,
      },
    },
  }),
  { name: 'SubjectBar' },
);

const DEFAULT_SUBJECTS = ['mathematics', 'biology', 'physics', 'chemistry', 'accounting', 'economics', 'statistics'];
export default function SubjectBar(props) {
  const {
    className,
    getPath,
    includeAllSubjects,
    onChange,
    subjects = DEFAULT_SUBJECTS,
    value,
    variant,
    wrapped,
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classNames(className, classes.root, { [classes.root_wrapped]: wrapped })}>
      {includeAllSubjects && renderSubjectButton({ ...getCourseConfig(), key: 'all', name: 'All Subjects' })}
      {subjects.map(k => SUBJECT_CONFIG[k]).map(config => renderSubjectButton(config))}
    </div>
  );

  function renderSubjectButton({ key, name, short_name }) {
    const isAll = key === 'all';
    const isActive = key === value || (isAll && !value);
    const label = short_name || name;
    const btnProps =
      getPath ?
        {
          component: Link,
          to: getPath(key),
          onClick: () => landingPageCTAClicked('subject_bar', 'subject', key),
        }
      : {
          onClick: () => {
            landingPageCTAClicked('subject_bar', 'subject', key);
            onChange(isAll ? undefined : key);
          },
        };
    if (variant === 'large') {
      return (
        <ButtonBase
          key={key}
          className={classNames(classes.subjectFilter, classes.subjectFilter_large, {
            [classes.subjectFilter_active]: isActive,
          })}
          {...btnProps}
        >
          <SubjectIcon className={classes.subjectIcon} subject={key} />
          <Typography className={classes.subjectFilterLabel} noWrap variant="body2">
            {label}
          </Typography>
        </ButtonBase>
      );
    }
    return (
      <div
        key={key}
        className={classNames(classes.subjectFilter, {
          [classes.subjectFilter_active]: isActive,
        })}
      >
        <IconButton className={classes.subjectFilterIconBtn} {...btnProps}>
          <SubjectIcon className={classes.subjectIcon} subject={key} />
        </IconButton>
        <Typography className={classes.subjectFilterLabel} noWrap>
          {label}
        </Typography>
      </div>
    );
  }
}

SubjectBar.propTypes = {
  includeAllSubjects: PropTypes.bool,
  onChange: PropTypes.func,
  getPath: PropTypes.func,
  subject: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(['small', 'large']),
};

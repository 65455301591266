import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { debounce } from 'my-utils';

import ErrorMessage from 'my-elements/ErrorMessage';
import InfiniteScroll from 'my-elements/InfiniteScroll';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles(
  ({ spacing, transitions: { create, duration } }) => ({
    root: {},
    enter: {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    enter_active: {
      opacity: 1,
      transform: 'translateY(0px)',
      transition: create(['opacity', 'transform'], { duration: duration.enteringScreen }),
    },
    seeMoreWrapper: {
      textAlign: 'center',
      marginTop: spacing(6),
    },
  }),
  { name: 'ItemList' },
);

export default function ItemList(props) {
  const classes = useStyles(props);

  const rootEl = useRef(null);

  const { duration } = useTheme().transitions;
  const {
    addedRows = 2,
    ButtonProps,
    className,
    component: Component,
    componentProps,
    continuous,
    defaultRows = 0,
    entity = 'item',
    entityKey = 'id',
    fetchError,
    fetching,
    fetchMore,
    idxForKey,
    items,
    onSeeMoreClicked,
    total,
  } = props;

  const [columns, setColumns] = useState(0);
  const [rows, setRows] = useState(defaultRows);
  useEffect(() => setRows(defaultRows), [defaultRows]);

  const calculateColumns = useCallback(() => {
    const elementPositions = ((rootEl.current && [...rootEl.current.childNodes]) || []).map(n => n.offsetLeft);
    const firstElement = elementPositions[0];
    const elemsPerRow = elementPositions.slice(1).findIndex(e => firstElement === e) + 1;

    // if !elementsPerRow, the number of elements don't cover the entire first row
    if (!elemsPerRow && items.length <= elementPositions.length) fetchMore && fetchMore();
    setColumns(elemsPerRow);
  }, [fetchMore, items.length]);

  useEffect(() => {
    if (continuous) return;

    const callback = debounce(calculateColumns, 400);
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [continuous, calculateColumns]);

  useEffect(() => {
    if (continuous) return;

    if (!columns) {
      calculateColumns();
      return;
    }
    if (!fetchMore) return;
    if (items.length < rows * columns) {
      fetchMore();
      return;
    }
    if (!rows) setRows(Math.floor(items.length / columns) || 1);
  }, [calculateColumns, columns, continuous, fetchMore, items, rows]);

  if (fetchError) return <ErrorMessage error={fetchError} />;

  const propsIsFunction = typeof componentProps === 'function';
  const displayedItems = rows * columns || items.length;

  return (
    <>
      <div className={classNames(className, classes.root)} ref={rootEl}>
        <TransitionGroup component={null} exit={false}>
          {items.slice(0, displayedItems).map((item, idx) => (
            <CSSTransition
              key={idxForKey ? idx : item[entityKey]}
              classNames={{
                appear: classes.enter,
                appearActive: classes.enter_active,
                enter: classes.enter,
                enterActive: classes.enter_active,
              }}
              timeout={{
                appear: duration.enteringScreen,
                enter: duration.enteringScreen,
              }}
            >
              <Component {...(propsIsFunction ? componentProps(item) : { [entity]: item, ...componentProps })} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      {renderLoader()}
    </>
  );

  function renderLoader() {
    if (continuous) return <InfiniteScroll fetching={fetching} onFetchMore={fetchMore} />;
    if (fetching) return <LinearProgress variant="query" />;

    if (displayedItems >= total) return null;

    return (
      <div className={classes.seeMoreWrapper}>
        <Button
          onClick={() => {
            onSeeMoreClicked?.();
            setRows(rows + addedRows);
          }}
          variant="contained"
          {...ButtonProps}
        >
          See More
        </Button>
      </div>
    );
  }
}

ItemList.propTypes = {
  addedRows: PropTypes.number,
  ButtonProps: PropTypes.object,
  className: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  componentProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  continuous: PropTypes.bool, // should not change over lifespan of component
  defaultRows: PropTypes.number,
  entity: PropTypes.string,
  entityKey: PropTypes.string,
  fetching: PropTypes.bool,
  fetchError: PropTypes.object,
  fetchMore: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  idxForKey: PropTypes.bool, // use idx for key to avoid re-transition when elements change (eg. if using skeletons for loading)
  items: PropTypes.array.isRequired,
  onSeeMoreClicked: PropTypes.func,
  total: PropTypes.number,
};

import standardApiRequestAction from 'my-core/standardApiRequestAction';

export function fetchSchoolApi(schoolId, fetchParams, requestKey) {
  return standardApiRequestAction({
    method: 'get',
    path: `/api/v1/schools/${schoolId}`,
    body: fetchParams,
    requestKey,
  });
}

export function fetchFooterSchools(countryCode, requestKey) {
  return standardApiRequestAction({
    method: 'get',
    path: '/api/v1/schools/footer',
    body: { country_code: countryCode, static: true }, // static=true causes Cloudflare custom page rule to cache response
    requestKey,
  });
}

export function fetchSchoolsApi(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/schools',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function searchSchools(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/schools/search',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function createSchool(body) {
  return standardApiRequestAction({
    path: '/api/v1/schools',
    method: 'post',
    body,
  });
}

export function updateSchool(id, body) {
  return standardApiRequestAction({
    path: `/api/v1/schools/${id}`,
    method: 'patch',
    body,
  });
}

export function mergeSchools(origId, targetId) {
  return standardApiRequestAction({
    path: `/api/v1/schools/${origId}/merge`,
    method: 'put',
    body: { target_id: targetId },
    destroy: { schools: [targetId] },
  });
}

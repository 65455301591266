import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import LinearProgress from '@mui/material/LinearProgress';

function InfiniteScroll(props) {
  const { fetching, onFetchMore } = props;

  const [ref, inView] = useInView({
    rootMargin: '100px 0px',
  });

  useEffect(() => {
    if (fetching || !onFetchMore || !inView) return;
    return onFetchMore();
  }, [fetching, onFetchMore, ref, inView]);

  // adding 'key' as hack for bug introduced by this change in rso: https://github.com/thebuilder/react-intersection-observer/pull/607
  // prevents the inView state from properly resetting after 2+ onFetchMore calls unless ref element changes (this hack causes extra re-renders)
  return fetching || onFetchMore ?
      <LinearProgress key={fetching ? '1' : '2'} ref={onFetchMore ? ref : undefined} />
    : null;
}

InfiniteScroll.propTypes = {
  fetching: PropTypes.bool,
  onFetchMore: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};
export default InfiniteScroll;

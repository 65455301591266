import { useTheme } from '@mui/material/styles';

import { getCourseConfig, getSubjectConfig } from 'my-core/course-utils';

import accountingIcon from 'images/subjects/icons-no-background/accounting.svg';
import biochemIcon from 'images/subjects/icons-no-background/biochem.svg';
import biologyIcon from 'images/subjects/icons-no-background/biology.svg';
import businessIcon from 'images/subjects/icons-no-background/business.svg';
import chemistryIcon from 'images/subjects/icons-no-background/chemistry.svg';
import compsciIcon from 'images/subjects/icons-no-background/compsci.svg';
import economicsIcon from 'images/subjects/icons-no-background/economics.svg';
import engineeringIcon from 'images/subjects/icons-no-background/engineering.svg';
import englishIcon from 'images/subjects/icons-no-background/english.svg';
import financeIcon from 'images/subjects/icons-no-background/finance.svg';
import generalIcon from 'images/subjects/icons-no-background/general.svg';
import logicIcon from 'images/subjects/icons-no-background/logic.svg';
import marketingIcon from 'images/subjects/icons-no-background/marketing.svg';
import mathematicsIcon from 'images/subjects/icons-no-background/mathematics.svg';
import orgchemIcon from 'images/subjects/icons-no-background/orgchem.svg';
import physicsIcon from 'images/subjects/icons-no-background/physics.svg';
import physiologyIcon from 'images/subjects/icons-no-background/physiology.svg';
import psychologyIcon from 'images/subjects/icons-no-background/psychology.svg';
import readingIcon from 'images/subjects/icons-no-background/reading.svg';
import reasoningIcon from 'images/subjects/icons-no-background/reasoning.svg';
import statisticsIcon from 'images/subjects/icons-no-background/statistics.svg';
import wizeIcon from 'images/subjects/icons-no-background/wize.svg';

const ICON_BY_SUBJECT = {
  accounting: accountingIcon,
  biochem: biochemIcon,
  biology: biologyIcon,
  business: businessIcon,
  chemistry: chemistryIcon,
  compsci: compsciIcon,
  economics: economicsIcon,
  engineering: engineeringIcon,
  english: englishIcon,
  finance: financeIcon,
  general: generalIcon,
  logic: logicIcon,
  marketing: marketingIcon,
  mathematics: mathematicsIcon,
  orgchem: orgchemIcon,
  physics: physicsIcon,
  physiology: physiologyIcon,
  psychology: psychologyIcon,
  reading: readingIcon,
  reasoning: reasoningIcon,
  statistics: statisticsIcon,
  wize: wizeIcon,
};

const classes = {
  border: { fill: 'none', strokeWidth: 2 },
};

export default function SubjectIcon({ course, subject: subjectProp, variant, ...rest }) {
  const { color, key: subject } = subjectProp ? getSubjectConfig(subjectProp) : getCourseConfig(course);
  const subjectIcon = ICON_BY_SUBJECT[subject] || ICON_BY_SUBJECT.general;
  const mode = useTheme().palette.mode;
  variant =
    !variant ?
      mode === 'dark' ?
        'outlined'
      : 'contained'
    : variant;

  // We use an SVG implementation so the border weight (stroke-width) is
  // responsive to the size of the icon. Otherwise, a static 1px border width for a 30x30
  // icon feels heavy but the same border on a 120x120 icon feels light.
  return (
    <svg viewBox="0 0 100 100" {...rest}>
      {variant === 'contained' ?
        <circle css={({ palette }) => ({ fill: palette[color].background })} cx="50" cy="50" r="50" />
      : variant === 'outlined' ?
        <circle css={[classes.border, ({ palette }) => ({ stroke: palette[color].main })]} cx="50" cy="50" r="49" />
      : null}
      <image height="100" href={subjectIcon} width="100" />
    </svg>
  );
}
